import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["results"]

  selected(event) {
    const prefecture_id = event.target.value
    const selectBox = this.resultsTarget;

    Rails.ajax({
      type: "get",
      url: `/cities?prefecture_id=${prefecture_id}`,
      success: function(response) {
        selectBox.innerHTML = '';
        response.forEach(item => {
          const opt = document.createElement('option');
          opt.value = item.id;
          opt.innerHTML = item['name'];
          selectBox.appendChild(opt);
        });
      }
    })
  }
}
