import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["promotions", "promotionmaterials", "promotionmaterial", "promotionmaterialdisplay", "promotionincentives"]

  selected(event) {
    const affiliaterMediumId = event.target.value
    const promotionSelectBox = this.promotionsTarget
    const promotionMaterialSelectBox = this.promotionmaterialsTarget

    Rails.ajax({
      type: "get",
      url: `/tenant_admins/affiliater_media/${affiliaterMediumId}/promotions.json`,
      success: function(response) {
        promotionSelectBox.innerHTML = '';
        const opt = document.createElement('option');

        promotionSelectBox.appendChild(opt);

        response.forEach(item => {
          const opt = document.createElement('option');
          opt.value = item.id;
          opt.innerHTML = item['title'];
          promotionSelectBox.appendChild(opt);
        });
      }
    })

    Rails.ajax({
      type: "get",
      url: `/tenant_admins/affiliater_media/${affiliaterMediumId}/promotion_materials.json`,
      success: function(response) {
        promotionMaterialSelectBox.innerHTML = '';
        response.forEach(item => {
          const element = document.createElement('div');
          element.innerHTML = item.html
          promotionMaterialSelectBox.appendChild(element);
        });
      }
    })
  }

  materialselected(event) {
    const promotionMaterialId = event.target.id
    const hiddenField = this.promotionmaterialTarget
    const display = this.promotionmaterialdisplayTarget

    display.innerHTML = ""
    hiddenField.value = promotionMaterialId

    const element = document.createElement('p')
    element.innerHTML = promotionMaterialId
    display.appendChild(element)
  }

  promotionselected(event) {
    const promotionId = event.target.value
    const promotionIncentiveSelectBox = this.promotionincentivesTarget

    Rails.ajax({
      type: "get",
      url: `/tenant_admins/promotions/${promotionId}/promotion_incentives.json`,
      success: function(response) {
        promotionIncentiveSelectBox.innerHTML = ''
        const opt = document.createElement('option')

        promotionIncentiveSelectBox.appendChild(opt)

        response.forEach(item => {
          const opt = document.createElement('option')
          opt.value = item.id
          opt.innerHTML = item['title']
          promotionIncentiveSelectBox.appendChild(opt)
        })
      }
    })
  }
}
