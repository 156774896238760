import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["results"]

  selected(event) {
    const status = event.target.value
    const textArea = this.resultsTarget

    if (status === "rejected") {
      textArea.classList.remove("d-none")
    } else {
      textArea.classList.add("d-none")
    }
  }
}
