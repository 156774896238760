import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["results"]

  checked(event) {
    const recipientType = event.target.value
    const selectBox = this.resultsTarget;
    const pathName = window.location.pathname
    const currentNameSpace = pathName.split('/')[1]

    Rails.ajax({
      type: "get",
      url: `/${currentNameSpace}/conversations/recipients?recipient_type=${recipientType}`,
      success: function(response) {
        selectBox.innerHTML = '';
        response.forEach(item => {
          const opt = document.createElement('option');
          opt.value = item.id;
          opt.innerHTML = item['name'];
          selectBox.appendChild(opt);
        });
      }
    })
  }
}
