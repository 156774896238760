import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["phpjs", "js"]

  checked(event) {
    const value = event.target.value

    if (value == "phpjs") {
      this.phpjsTarget.style = ""
      this.jsTarget.style = "display:none;"
    } else {
      this.phpjsTarget.style = "display:none;"
      this.jsTarget.style = ""
    }
  }
}
