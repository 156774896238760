import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["assign", "output"]

  subjectAssigns(event) {
    event.preventDefault()
    const value = event.target.value
    const assignValue = this.assignTarget.value

    const prevValue = this.outputTarget.value
    this.outputTarget.value = prevValue + assignValue
  }

  bodyAssigns(event) {
    event.preventDefault()
    const value = event.target.value
    const assignValue = this.assignTarget.value

    const prevValue = this.outputTarget.value
    this.outputTarget.value = prevValue + assignValue
  }
}
