require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap")
require("@fortawesome/fontawesome-free")
window.$ = window.jQuery = require("jquery")
import "../src/application.scss"
require("feather-icons")
require("chart.js")
window.feather = require("feather-icons")

import "controllers"

require("trix")
require("@rails/actiontext")
require("jquery")
require("@nathanvda/cocoon")
